import React from "react";
import { useActions } from "../main";
import SlFormatDate from "@shoelace-style/shoelace/dist/react/format-date";

const Message: ({ createdAt }: { createdAt: Date }) => JSX.Element = ({
  createdAt,
}: {
  createdAt: Date;
}) => {
  const { login } = useActions();

  return (
    <>
      <small>
        <SlFormatDate
          date={createdAt}
          month="long"
          day="numeric"
          year="numeric"
          hour="numeric"
          minute="numeric"
        />
      </small>
    </>
  );
};

export default Message;
