import {
  i
} from "./chunk.CXZZ2LVK.js";

// src/components/mutation-observer/mutation-observer.styles.ts
var mutation_observer_styles_default = i`
  :host {
    display: contents;
  }
`;

export {
  mutation_observer_styles_default
};
