import { graphql } from "overmind-graphql";

import * as communitiesQueries from "./communities/queries";
import * as communitiesMutations from "./communities/mutations";

import * as messagesQueries from "./messages/queries";
import * as messagesMutations from "./messages/mutations";

import * as operatorsQueries from "./operators/queries";
import * as operatorsMutations from "./operators/mutations";

export default graphql({
  subscriptions: {},
  queries: {
    ...operatorsQueries,
    ...communitiesQueries,
    ...messagesQueries,
  },
  mutations: {
    ...communitiesMutations,
    ...messagesMutations,
    ...operatorsMutations,
  },
});
