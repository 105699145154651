import {
  i
} from "./chunk.CXZZ2LVK.js";

// src/components/qr-code/qr-code.styles.ts
var qr_code_styles_default = i`
  :host {
    display: inline-block;
  }
`;

export {
  qr_code_styles_default
};
