import React from "react";
import { useActions, useAppState } from "../main";
import SlCard from "@shoelace-style/shoelace/dist/react/card";
import SlDialog from "@shoelace-style/shoelace/dist/react/dialog";
import SlButton from "@shoelace-style/shoelace/dist/react/button";

import { comlist, fillHeight, header } from "../stitches.config";
import SlIconButton from "@shoelace-style/shoelace/dist/react/icon-button";
import SlSelect from "@shoelace-style/shoelace/dist/react/select";
import CreatedAt from "../components/CreatedAt";
import { RunMode } from "../main/state";
import { SlOption } from "@shoelace-style/shoelace/cdn/react";

const CommunitiesListPage: React.FunctionComponent = () => {
  const {
    getCommunities,
    manage,
    assignOperator,
    setCommunityIdForOperatorAssignment,
    deleteCommunity,
  } = useActions().communities;
  const { getOperators } = useActions().operators;
  const { list, communityIdForOperatorAssignment } = useAppState().communities;
  const operators = useAppState().operators;
  const runMode = useAppState().runMode;

  React.useEffect(() => {
    getCommunities();
    getOperators();
  }, []);
  return (
    <>
      <div className={fillHeight()}>
        <div className={comlist()}>
          {list
            .map(({ name, description, id, created_at, operator_id }) => {
              return (
                <SlCard key={id} className={comlist()}>
                  <span className={header()} slot="header">
                    <strong>{name}</strong>
                    {runMode === RunMode.superadmin && (
                      <SlIconButton
                        // @ts-ignore
                        // push to the right
                        style={{ marginLeft: "auto" }}
                        onClick={() => {
                          // console.log({ route: "/operators/manage" });
                          const dialog: any = document.querySelector(
                            ".assignOperatorDialog",
                          );
                          dialog.show({ communityId: id });
                          setCommunityIdForOperatorAssignment({
                            communityId: id,
                          });
                          // assignOperator({ communityId: id })
                        }}
                        name="person-plus"
                        label="Assign Operator"
                      />
                    )}
                    <SlIconButton
                      onClick={() => manage({ name, id })}
                      name="list"
                      label="Manage"
                    />
                    {runMode === RunMode.superadmin && (
                      <SlIconButton
                        onClick={() => deleteCommunity({ communityId: id })}
                        name="trash"
                        label="Delete Communities"
                      />
                    )}
                  </span>
                  <span>{description}</span>
                  <br />
                  <CreatedAt createdAt={created_at} />
                  <br />

                  {operator_id && (
                    // @ts-ignore
                    <span>
                      Assigned Operator:
                      {
                        // @ts-ignore
                        operators.map[operator_id] &&
                          // @ts-ignore
                          operators.map[operator_id].name
                      }
                      {/*{JSON.stringify(operators.map[operator_id])}*/}
                    </span>
                  )}
                </SlCard>
              );
            })
            .reverse()}
        </div>
      </div>
      {/* Hidden dialog for asking add new email */}
      <SlDialog label="Assign an Operator" className="assignOperatorDialog">
        <SlSelect id="selectedOperator" placeholder="Select one">
          {operators.list.map((operator) => {
            return (
              <SlOption key={operator.id} value={operator.id}>
                {operator.name}
              </SlOption>
            );
          })}
        </SlSelect>
        <SlButton
          onClick={() => {
            var e: any = document.getElementById("selectedOperator");
            let operatorId;
            if (e.value) {
              operatorId = e.value;
              assignOperator({
                operatorId,
                communityId: communityIdForOperatorAssignment,
              });
              const dialog: any = document.querySelector(
                ".assignOperatorDialog",
              );
              dialog.hide();
            }
          }}
          slot="footer"
        >
          Assign
        </SlButton>
      </SlDialog>
    </>
  );
};

export default CommunitiesListPage;
