import CommunitiesCreatePage from "./CommunitiesCreatePage";
import CommunitiesListPage from "./CommunitiesListPage";
import CommunitiesManagePage from "./CommunitiesManagePage";
import ManageOperatorsPage from "./ManageOperatorsPage";
import SendMessagePage from "./SendMessagePage";
import LoginPage from "./LoginPage";
import RootPage from "./RootPage";

export default [
  { Page: LoginPage, route: "/login" },
  { Page: RootPage, route: "/" },
  { Page: CommunitiesCreatePage, route: "/communities/create" },
  { Page: CommunitiesListPage, route: "/communities/list" },
  { Page: CommunitiesManagePage, route: "/communities/manage/:communityId" },
  { Page: ManageOperatorsPage, route: "/operators/manage" },
  { Page: SendMessagePage, route: "/send-message" },
];
