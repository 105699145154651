import React from "react";
import { useActions } from "../main";
import SlButton from "@shoelace-style/shoelace/dist/react/button";
import SlInput from "@shoelace-style/shoelace/dist/react/input";

const LoginPage: React.FunctionComponent = () => {
  const { login } = useActions();

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const form = document.querySelector("form");
          const data = new FormData(form as HTMLFormElement);
          const email = data.get("email") as string;
          const password = data.get("password") as string;
          console.log({ email, password });
          login({ email, password });
        }}
      >
        <SlInput
          name="email"
          label="Email"
          type="email"
          placeholder="Username"
          required
        />
        <br />
        <SlInput
          name="password"
          label="Password"
          type="password"
          placeholder="Password"
          toggle-password
          required
        />
        <br />
        <SlButton type="submit">Submit</SlButton>
      </form>
    </>
  );
};

export default LoginPage;
