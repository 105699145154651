type Operator = {
  id: string;
  name: string;
  email: string;
  created_at: Date;
  updated_at: Date;
  otp_secret: string;
};

type State = {
  list: Operator[];
  map: {};
};

export const state: State = {
  list: [],
  map: {},
};
