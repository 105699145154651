import {
  i
} from "./chunk.CXZZ2LVK.js";

// src/components/animation/animation.styles.ts
var animation_styles_default = i`
  :host {
    display: contents;
  }
`;

export {
  animation_styles_default
};
