import {
  en_default
} from "./chunk.QPSNFEB2.js";
import {
  LocalizeController,
  registerTranslation
} from "./chunk.O27EHOBW.js";

// src/utilities/localize.ts
var LocalizeController2 = class extends LocalizeController {
};
registerTranslation(en_default);

export {
  LocalizeController2 as LocalizeController
};
