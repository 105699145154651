import React from "react";
import { useActions } from "../main";

import { css } from "../stitches.config";
import SlButton from "@shoelace-style/shoelace/dist/react/button";
import SlTextarea from "@shoelace-style/shoelace/dist/react/textarea";
import SlInput from "@shoelace-style/shoelace/dist/react/input";

const textarea = css({
  height: "50vh",
});
const mess = css({
  width: "100%",
  height: "96vh",
  overflowY: "scroll",
});

const card = css({
  width: "100%",
  paddingRight: "1rem",
  paddingBottom: "1rem",
});

const rightPane = css({
  width: "60vw",
  paddingLeft: "1rem",
});

const submit = css({
  width: "100%",
});

const CommunitiesManagePage: React.FunctionComponent = () => {
  const { sendMessage } = useActions();

  return (
    <>
      <form
        onSubmit={(e: any) => {
          e.preventDefault();
          const form = document.querySelector("form");
          const data = new FormData(form as HTMLFormElement);
          const recipient = data.get("recipient") as string;
          const body = data.get("body") as string;
          const from = data.get("from") as string;
          sendMessage({ recipient, body, from });

          // clear the form
          (form as HTMLFormElement).reset();
        }}
      >
        <SlInput name="from" label="From" placeholder="from title" required />
        <br />
        <SlInput
          name="recipient"
          label="Recipient"
          type="number"
          placeholder="Phone number without +"
          required
        />
        <br />
        <SlTextarea
          name="body"
          label="Body"
          placeholder="write your message here"
          required
        />
        <br />
        <SlButton type="submit">Submit</SlButton>
      </form>
    </>
  );
};

export default CommunitiesManagePage;
