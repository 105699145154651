import { derived } from "overmind";

export enum RunMode {
  superadmin = "superadmin",
  operator = "admin",
}

export enum AlertType {
  success = "success",
  error = "error",
}
export type Alert = {
  id: string;
  message: string;
  type: AlertType;
};

export enum DialogButton {
  none = "none",
  yes = "yes",
  no = "no",
}

export type State = {
  runMode: RunMode;
  redirectTo: string;
  authToken: string;
  toastComponents: [];
  isLoggedIn: boolean;
  rootDialogClickedValue: DialogButton;
};

// @ts-ignore
const CONFIG = window["CONFIG"];

export const state: State = {
  runMode: RunMode.operator,
  redirectTo: "",
  authToken: "",
  toastComponents: [],
  isLoggedIn: derived((state: State, rootState) => !!state.authToken),
  rootDialogClickedValue: DialogButton.none,
};
