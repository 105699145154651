import {
  i
} from "./chunk.CXZZ2LVK.js";

// src/components/resize-observer/resize-observer.styles.ts
var resize_observer_styles_default = i`
  :host {
    display: contents;
  }
`;

export {
  resize_observer_styles_default
};
