import {
  format_date_default
} from "../../chunks/chunk.ASIR5L6L.js";
import "../../chunks/chunk.BI5QPUKX.js";
import "../../chunks/chunk.WLV3FVBR.js";
import "../../chunks/chunk.MAS2SHYD.js";
import "../../chunks/chunk.TUZSOS6C.js";
import "../../chunks/chunk.IFDWM6P4.js";
export {
  format_date_default as default
};
