import React from "react";
import { useActions } from "../main";
import SlButton from "@shoelace-style/shoelace/dist/react/button";
// import SlForm from "@shoelace-style/shoelace/dist/react/form";
import SlInput from "@shoelace-style/shoelace/dist/react/input";
import SlTextarea from "@shoelace-style/shoelace/dist/react/textarea";
import SlDivider from "@shoelace-style/shoelace/dist/react/divider";

const CommunitiesCreatePage: React.FunctionComponent = () => {
  const { create } = useActions().communities;

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const form = document.querySelector("form");
          const data = new FormData(form as HTMLFormElement);
          const name = data.get("name") as string;
          const description = data.get("description") as string;
          create({ name, description });
        }}
      >
        <SlInput
          name="name"
          label="Name"
          type="text"
          placeholder="Name"
          required
        />
        <SlDivider />
        <SlTextarea
          name="description"
          label="Description"
          placeholder="Description"
          required
        />
        <SlDivider />
        <SlButton type="submit">Submit</SlButton>
      </form>
    </>
  );
};

export default CommunitiesCreatePage;
