import React from "react";
import { useActions } from "../main";

const LoginPage: React.FunctionComponent = () => {
  const { login } = useActions();

  return (
    <>
      <div>Welcome</div>
    </>
  );
};

export default LoginPage;
