import { Context } from "../../index";
import { DialogButton } from "../../state";

export const getOperators = async ({ state, effects, actions }: Context) => {
  try {
    const { operators } = await effects.gql.queries.getOperators({});

    if (operators.length) {
      state.operators.list = operators;
      operators.forEach((operator: any) => {
        // @ts-ignore
        state.operators.map[operator.id] = operator;
      });
    }
  } catch (error) {
    actions.toast({ error });
  }
};

export const createNewOperator = async (
  { state, effects, actions }: Context,
  { email, name }: { name: string; email: string },
) => {
  try {
    const { insert_operators_one } =
      await effects.gql.mutations.createNewOperator({
        email,
        name,
      });

    if (insert_operators_one && insert_operators_one.id) {
      state.operators.list.push({ ...insert_operators_one });
      actions.toast({ message: "Success" });
    }
  } catch (error) {
    actions.toast({ error });
  }
};

export const deleteOperator = async (
  { state, effects, actions }: Context,
  { email, index }: { email: string; index: number },
) => {
  try {
    const { answer } = await actions.confirmDialog();
    if (answer === DialogButton.yes) {
      const { delete_operators_by_pk } =
        await effects.gql.mutations.deleteOperator({
          email,
        });

      if (delete_operators_by_pk) {
        actions.toast({ message: "Success" });
      }
      if (delete_operators_by_pk === null) {
        actions.toast({ message: "Already Deleted" });
      }
      state.operators.list.splice(index, 1);
    }
  } catch (error) {
    actions.toast({ error });
  }
};
