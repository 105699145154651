import React from "react";

import { css, menu } from "../stitches.config";
import SlMenu from "@shoelace-style/shoelace/dist/react/menu";
import SlMenuItem from "@shoelace-style/shoelace/dist/react/menu-item";
import SlDivider from "@shoelace-style/shoelace/dist/react/divider";
import { useActions, useAppState } from "../main";
import { RunMode } from "../main/state";

const sidebar = css({
  height: "100%",
  paddingRight: "1rem",
  // margin: "0.5rem"
});

const LeftSidebar: React.FunctionComponent = () => {
  const { runMode } = useAppState();
  const { nav, signOut } = useActions();

  const isSuperAdmin = runMode === RunMode.superadmin;
  const isOperator = runMode === RunMode.operator;

  const OperatorMenu = () => (
    <div>
      <SlMenu className={menu()}>
        <SlMenuItem
          onClick={() => {
            nav({ route: "/communities/list" });
          }}
        >
          List Communities
        </SlMenuItem>
        <SlDivider />
        <SlMenuItem
          onClick={() => {
            signOut();
          }}
        >
          Sign out
        </SlMenuItem>
      </SlMenu>
    </div>
  );

  const SuperAdminMenu = () => (
    <div>
      <SlMenu className={menu()}>
        <SlMenuItem
          onClick={() => {
            nav({ route: "/operators/manage" });
          }}
        >
          Manage Operators
        </SlMenuItem>
        <SlDivider />
        <SlMenuItem
          onClick={() => {
            nav({ route: "/communities/create" });
          }}
        >
          Create Communities
        </SlMenuItem>
        <SlDivider />
        <SlMenuItem
          onClick={() => {
            nav({ route: "/communities/list" });
          }}
        >
          List Communities
        </SlMenuItem>
        <SlDivider />
        <SlMenuItem
          onClick={() => {
            nav({ route: "/send-message" });
          }}
        >
          Send Message
        </SlMenuItem>
        <SlDivider />
        <SlMenuItem
          onClick={() => {
            signOut();
          }}
        >
          Sign out
        </SlMenuItem>
      </SlMenu>
    </div>
  );

  return (
    <aside className={sidebar()}>
      {isSuperAdmin && <SuperAdminMenu />}
      {isOperator && <OperatorMenu />}
    </aside>
  );
};

export default LeftSidebar;
