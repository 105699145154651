import React from "react";
import { useActions, useAppState } from "../main";
import SlButton from "@shoelace-style/shoelace/dist/react/button";
import SlCard from "@shoelace-style/shoelace/dist/react/card";
import SlTextarea from "@shoelace-style/shoelace/dist/react/textarea";

import { css, header } from "../stitches.config";
import { useParams } from "react-router-dom";
import CreatedAt from "../components/CreatedAt";
import {
  MessageSubtype,
  SupportedMimeTypesSubTypeMap,
} from "../main/namespaces/communities/state";
import SlIconButton from "@shoelace-style/shoelace/dist/react/icon-button";

const textarea = css({
  height: "50vh",
});
const mess = css({
  width: "100%",
  height: "96vh",
  overflowY: "scroll",
});

const card = css({
  width: "100%",
  paddingRight: "1rem",
  paddingBottom: "1rem",
});

const rightPane = css({
  width: "60vw",
  paddingLeft: "1rem",
});

const submit = css({
  width: "100%",
});

const CommunitiesManagePage: React.FunctionComponent = () => {
  const {
    manage,
    getMessages,
    submitMessage,
    setManageInput,
    uploadFile,
    deleteMessage,
  } = useActions().communities;
  const { list, messages, currentCommunityId, communityManageInput } =
    useAppState().communities;

  let { communityId } = useParams<Record<string, string | undefined>>();

  React.useEffect(() => {
    if (!currentCommunityId && communityId) {
      getMessages({ id: communityId });
    }
  }, []);
  return (
    <>
      <div className={mess()}>
        {messages
          .map(({ id, message, created_at, subtype, body }, index) => (
            <SlCard key={id} className={card()}>
              <span className={header()} slot="header">
                <strong>
                  <CreatedAt createdAt={created_at} />
                </strong>
                <SlIconButton
                  onClick={() => deleteMessage({ id, index })}
                  name="trash"
                  label="Delete"
                />
              </span>
              {subtype === MessageSubtype.picture && (
                <img
                  slot="image"
                  crossOrigin="anonymous"
                  src={body}
                  alt={body}
                />
              )}
              {subtype && (
                <a href={body} download target="_blank">
                  Download
                </a>
              )}
              {!subtype && <pre>{message}</pre>}
              <br />
              <CreatedAt createdAt={created_at} />
            </SlCard>
          ))
          .reverse()}
      </div>

      <div className={rightPane()}>
        <SlTextarea
          rows={25}
          onSlInput={(e) => {
            // @ts-ignore
            setManageInput({ value: e.target.value });
          }}
          value={communityManageInput}
        />
        <SlButton
          disabled={!communityManageInput}
          onClick={() => {
            submitMessage();
          }}
          className={submit()}
        >
          Submit
        </SlButton>
        <SlButton
          onClick={() => {
            // @ts-ignore
            document.getElementById("fileupload").click();
          }}
          className={submit()}
        >
          Upload File
        </SlButton>
        <input
          onChange={async (event) => {
            // @ts-ignore
            await uploadFile({ file: event.target.files[0] });

            // @ts-ignore
            document.getElementById("fileupload").value = "";
          }}
          hidden
          accept={Object.keys(SupportedMimeTypesSubTypeMap)
            .map((key) => (isNaN(parseInt(key)) ? key : false))
            .join(",")}
          id="fileupload"
          type="file"
        />
      </div>
    </>
  );
};

export default CommunitiesManagePage;
